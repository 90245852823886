import {graphql, Link} from 'gatsby';
import React from 'react';

import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Van softwareproduct naar zelfvoorzienend productbedrijf – Stap 5 van onze aanpak"
        description="Producten die wij ontwikkelen moeten onze dienstverlening kunnen ontgroeien. Daarom helpen we met het opzetten van een intern team bij de opdrachtgever."
        ogImage="capability-transfer.png"
        translatedPagePath="/en/approach/capability-transfer/">
        <Link
            to="/nl/werkwijze/continue-doorontwikkeling/"
            className="body__nav body__nav--left"
            title="Aanpakstap 4B. Doorontwikkelen">
            Vorige stap
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Van product <br /> naar zelf&shy;voorzienend <br /> product&shy;bedrijf
                        </h2>
                        <div className="display__number">5</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Producten die wij ontwikkelen moeten onze dienstverlening kunnen ontgroeien. Daarom helpen{' '}
                            we met het opzetten van een intern product&shy;ontwikkelings&shy;team bij de{' '}
                            opdracht&shy;gever.
                        </p>
                        <p>
                            We starten in overleg met een{' '}
                            <span className="highlight">ontwikkelings&shy;transitieproces</span>. Dit is een proces{' '}
                            waarbij wij samen met onze opdracht&shy;gever een intern product&shy;team gaan opbouwen. Dit{' '}
                            team zal uiteindelijk zelf doorontwikkelen en de productieomgeving beheren.
                        </p>
                        <p>
                            Wij lichten voor de opdracht&shy;gever kandidaten door en laten ze intern bij ons meedraaien{' '}
                            tijdens de door&shy;ontwikkeling van het product. In de praktijk leren ze alles over onze{' '}
                            methodieken en processen. Zo hebben de kandidaten na verloop van tijd alles in huis om zelf{' '}
                            de <span className="highlight">proces&shy;verantwoordelijk&shy;heid</span> over te nemen.
                        </p>
                        <p>
                            Na een transitie&shy;periode beschikt de opdracht&shy;gever over een intern{' '}
                            ontwikkel&shy;team op niveau waarmee de organisatie een{' '}
                            <span className="highlight">zelf&shy;voorzienend</span> product&shy;bedrijf is geworden.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Helemaal optioneel</li>
                            <li className="ion-md-arrow-dropright">Capability transfer</li>
                            <li className="ion-md-arrow-dropright">Minimaal 3 kandidaten</li>
                            <li className="ion-md-arrow-dropright">Vanaf 3 maanden per kandidaat</li>
                        </ul>
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
